import React, { useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import DOMPurify from 'dompurify';

const HTMLCard = ({ html, url, width, height }) => {
  const [iframeWidth, setIframeWidth] = useState(width);
  const [iframeHeight, setIframeHeight] = useState(height);
  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const newWidth = iframeDocument.body.scrollWidth;
      const newHeight = iframeDocument.body.scrollHeight + 50;
      setIframeWidth(newWidth);
      setIframeHeight(newHeight);
    }
  };

  const add_security_tags = (doc) => {
    // disables javascript
    const meta = doc.createElement('meta');
    meta.setAttribute('http-equiv', 'Content-Security-Policy');
    meta.setAttribute('content', "script-src 'none'");
    doc.head.appendChild(meta);

    const styleSheet = doc.createElement('style');
    styleSheet.innerText =
      'body { width: 800px; overflow: scroll; overflow-wrap: break-word; padding: 0px; } pre { white-space: pre-wrap; }';
    doc.head.appendChild(styleSheet);

    // makes all links open a new tab / window
    const base = doc.createElement('base');
    base.setAttribute('target', '_blank');
    doc.head.appendChild(base);
  };

  const sanitize_html = (html) => {
    // DOMPurify protects against XSS attacks
    let clean_html = DOMPurify.sanitize(html);
    const parser = new DOMParser();
    const doc = parser.parseFromString(clean_html, 'text/html');

    add_security_tags(doc);

    const serializer = new XMLSerializer();
    return serializer.serializeToString(doc);
  };

  const srcDoc = html ? sanitize_html(html) : url;

  return (
    <Card className="HTMLCard">
      <CardActionArea>
        <div className="HTML-media">
          <iframe
            title="html-iframe"
            className="html-iframe"
            ref={iframeRef}
            onLoad={handleIframeLoad}
            srcDoc={srcDoc}
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin"
            csp="script-src 'none'"
            style={{ width: iframeWidth, height: iframeHeight }}
          />
        </div>
      </CardActionArea>
    </Card>
  );
};

export default HTMLCard;
