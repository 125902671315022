import React from 'react';
import get from 'lodash/get';
import ImageCard from './ImageCard';
import HTMLCard from './HTMLCard';
import CarouselComponent from './Carousel';
import ImageMessage from './ImageMessage';
import AudioMessage from './AudioMessage';
import DocumentMessage from './DocumentMessage';
import Linkify from 'react-linkify';

const RANDOM_BLOCK = 'RANDOM_MESSAGE_BLOCK';
const SIMPLE_TEXT_BLOCK = 'SIMPLE_TEXT_BLOCK';
const LINK_TEXT_BLOCK = 'LINK_TEXT_BLOCK';
const IMAGE_BLOCK = 'IMAGE_BLOCK';
const HTML_BLOCK = 'HTML_BLOCK';
const CAROUSEL_BLOCK = 'CAROUSEL_BLOCK';
const IMAGE_MESSAGE = 'IMAGE_MESSAGE';
const AUDIO_MESSAGE = 'AUDIO_MESSAGE';
const DOCUMENT_MESSAGE = 'DOCUMENT_MESSAGE';

const MessageRenderer = ({ message, isProactiveChat }) => {
  try {
    const messageType = get(message, 'meta.message_block_type');
    const defaultText = message.content;
    let imageUrl, fileUrl;

    switch (messageType) {
      case LINK_TEXT_BLOCK:
        const linkText = get(message, 'meta.linkText');
        const linkURL = get(message, 'meta.url');
        const styles = { color: '#00a5ff' };
        return (
          <a href={linkURL} target="_blank" rel="noopener noreferrer" style={styles}>
            {linkText || linkURL}
          </a>
        );
      case IMAGE_BLOCK:
        const text = get(message, 'meta.text');
        const titleLabel = get(message, 'meta.titleLabel');
        const titleURL = get(message, 'meta.url');
        const image = get(message, 'meta.image');
        return (
          <ImageCard
            text={text}
            titleLabel={titleLabel}
            titleURL={titleURL}
            image={image}
            rel="noopener noreferrer"
            isProactiveChat={isProactiveChat}
          />
        );

      case HTML_BLOCK:
        const htmlURL = get(message, 'meta.url');
        const html = get(message, 'meta.html');
        const width = get(message, 'meta.width');
        const height = get(message, 'meta.height');
        return <HTMLCard html={html} url={htmlURL} width={width} height={height} />;

      case CAROUSEL_BLOCK:
        const carouselElements = get(message, 'meta.carousel_elements');
        return (
          <CarouselComponent
            carouselElements={carouselElements}
            isProactiveChat={isProactiveChat}
          />
        );

      case IMAGE_MESSAGE:
        imageUrl = get(message, 'meta.imageUrl');
        return <ImageMessage imageUrl={imageUrl} />;

      case AUDIO_MESSAGE:
        fileUrl = get(message, 'meta.fileUrl');
        return <AudioMessage fileUrl={fileUrl} />;

      case DOCUMENT_MESSAGE:
        fileUrl = get(message, 'meta.fileUrl');
        return <DocumentMessage fileUrl={fileUrl} />;

      case SIMPLE_TEXT_BLOCK:
      case RANDOM_BLOCK:
        return (
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                target="blank"
                href={decoratedHref}
                key={key}
                rel="noopener noreferrer"
                style={{ color: 'white', fontStyle: 'italic' }}
              >
                {decoratedText}
              </a>
            )}
          >
            {get(message, 'meta.text')}
          </Linkify>
        );

      default:
        return defaultText;
    }
  } catch (error) {
    // Sentry.captureException(error);
    console.log('MESSAGE RENDERER FAILED - ', error);
  }
};

export default MessageRenderer;
